import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { Link } from "gatsby"
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import Button from '~components/blocks/Button'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const FeaturedCategorySection = ({ className, image, title, subtitle, imageTitle, text, link, reverse, borderBottom }) => {

  const {mobile, tablet} = breakpoints
  const [hovered, setHovered] = useState(true)

  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        padding: 0 40px;
        ${mobile}{
          grid-column-gap: 10px;
          padding: 0 25px;
        }
      `}
    >
        <div
          css={css`
            overflow: hidden;
            grid-column: ${reverse ? `7/13` : `1/7`};
            /* height: 100%; */
            align-self: start;
            position: relative;
            ${mobile}{
              grid-column: span 12;
              height: auto;
              margin-bottom: 40px;
              ${reverse ? `2` : `1`};
            }
        `}>
          <Link to={link}>
            <div css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              display: grid;
              align-items: end;
              padding: 25px;
              pointer-events: none;
              ${mobile}{
                padding: 10px 10px;
              }
            `}>
              <h6>{imageTitle}</h6>
            </div>
            <div css={css`
              transform: scale(1);
              transition: 0.4s transform;
              &:hover{
                transform: scale(1.03);
              }
            `}>
              <Image
                asset={image}
                aspectRatio={1.580}
                css={css`
                  height: 100%;
                  .gatsby-image-wrapper {
                    height: 100%;
                  }
                `}
              />
            </div>
          </Link>
        </div>
        <div
        css={css`
          overflow: hidden;
          grid-column: ${reverse ? `2/6` : `8 / 12`};
          grid-row: 1;
          /* align-self: center; */
          position: relative;
          margin-top: 30%;
          ${mobile}{
            margin-top: 0;
            grid-column: span 12;
            grid-row: ${reverse ? `1` : `2`};
            margin-bottom: ${reverse ? `36px;` : `0px`};
          }
        `}>
            <div 
              // onMouseEnter={() => setHovered(true)}
              // onMouseLeave={() => setHovered(false)}
            >
              <h4 css={css`
                margin-bottom: 28px;
                ${mobile}{
                  margin-bottom: 20px;
                }
              `}>
                {title}
              </h4>
              <h4 css={css`
                margin-bottom: 20px;
                ${mobile}{
                  margin-bottom: 15px;
                }
              `}>
                <Link to={link}>{subtitle}</Link>
              </h4>
              <SlideDown css={css`
                  transition-duration: 0.35s;
                  ${mobile}{
                    display: none;
                  }
                `}>
                  {hovered &&
                    <p css={css`
                      padding-bottom: 34px;
                    `}>{text}</p>
                  }
              </SlideDown>
              <Button 
                to={link}
                css={css`
                  padding-top: 10px;
                  ${mobile}{
                    margin-bottom: ${borderBottom ? `0px` : `106px;`};
                  }
                `}
              >See Collection</Button>
            </div>
            <div css={css`
              display: none;
              ${mobile}{
                display: block;
                border-bottom: ${borderBottom ? `none` : `1px solid var(--grey)`};
              }
            `}>
            </div>
        </div>
    </div>
  )
}

FeaturedCategorySection.propTypes = {
  image: PropTypes.object,
  category: PropTypes.string,
  title: PropTypes.string,
  imageTitle: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  reverse: PropTypes.bool,
}

FeaturedCategorySection.defaultProps = {
  category: `Desks`,
  title: `The Complete Home Office`,
  text: `Working from home has never looked so good — Browse our range of striking modular home office additions and solutions.`,
  // imageTitle: `Nasdaq Desk`,
  link: `/`,
  reverse: false,
}

export default FeaturedCategorySection
