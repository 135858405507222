import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Button from '~components/blocks/Button'
import CategoryCard from '~components/blocks/CategoryCard'
import * as Flickity from 'react-flickity-component'
import "~styles/flickity.css";
import { Larr, Rarr } from '../../Svg';
import resolveLink from '~utils/resolveLink'

const generateLink = doc => {
  if(!doc?._type) return null
  let url
  if(doc._type === 'productCategory'){
    url = resolveLink(doc._type, doc.title, doc.defaultParentCategory?.slug?.current)
  } else {
    url = resolveLink(doc._type, doc.slug.current)
  }
  return url
}

class CategoryGridSlider extends React.Component {

  render() {

    const myCustomPrev = () => {
      this.flkty.previous()
    }

    const myCustomNext = () => {
      this.flkty.next()
    }

    const flickityOptions = {
      initialIndex: 0,
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyLoad: 5,
    }

    return (
      <>
        <Flickity
            flickityRef={c => this.flkty = c}
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
            css={css`
              margin-bottom: 12px;
            `}
          >
            {this.props.categories?.map(category =>(
              <CategoryCard
                css={css`
                  width: 53%;
                  margin-right: 10px;
                `}
                title={category?.category?.title}
                image={category?.image?.asset?.id}
                text={category.text}
                link={generateLink(category.category)}
                key={category._key}
              />
            ))}
        </Flickity>
        <button 
          onClick={() => myCustomPrev()}
          css={css`
            margin-right: 56px;
            padding: 4px;
        `}>
          <Larr css={css`
            height: 11px;
            width: 11px;
          `}/>
        </button>
        <button 
          onClick={() => myCustomNext()}
        >          
          <Rarr css={css`
            height: 11px;
            width: 11px;
          `}/>
        </button>
      </>
    )
  }
}

const CategoryGridSection = ({ className, categoriesLink, categoriesLinkHeading, categories }) => {

  const {mobile} = breakpoints

  return (
    <Section className={className} >
      <Button css={css`
          grid-column: span 12;
          justify-self: start;
          margin-bottom: 108px;
          @media (max-width: 750px){
            margin-bottom: 66px;
          }
        `}
        to={categoriesLink}
      >
        {categoriesLinkHeading}
      </Button>
      <div css={css`
        grid-column: span 12;
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 8%;
        @media (max-width: 750px){
          display: none;
        }
      `}>
        {categories?.map((category) =>(
          <CategoryCard
            title={category?.category?.title}
            image={category?.image?.asset}
            text={category.text}
            link={generateLink(category.category)}
            key={category._key}
						aspectRatio={1}
          />
        ))}
      </div>
      <div css={css`
        grid-column: span 12;
        display: none;
        @media (max-width: 750px) {
          margin-right: -25px;
          display: block;
        }
      `}>
        <CategoryGridSlider categories={categories}/>
      </div>
    </Section>
  )
}

CategoryGridSection.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    key: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
  })),
  categoriesLinkHeading: PropTypes.string,
}

CategoryGridSection.defaultProps = {
  categoriesLinkHeading: `/`,
  categories: [
    {
      key:"category1",
      title:"Title 1",
      text:"The beauty we surround ourselves in, particularly within our living quarters, has a profound impact on our space.",
      link: "/" ,
    },
    {
      key:"category2",
      title:"Title 2",
      text:"The beauty we surround ourselves in, particularly within our living quarters, has a profound impact on our space.",
      link: "/"
    },
    {
      key:"category3",
      title:"Title 3",
      text:"The beauty we surround ourselves in, particularly within our living quarters, has a profound impact on our space.",
      link: "/"
    },
  ],
}

export default CategoryGridSection
