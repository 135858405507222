import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Button from '~components/blocks/Button'
// import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'

const HomeVideo = ({ className, video, videoTitle, videoText, videoLink }) => {
	const {mobile} = breakpoints
return (
	<Section
		className={className}
	>
		<div
			css={css`
			grid-column: 1 / 4;
				${mobile}{
					grid-column: 1 / 13;
				}
			`}
		>
		<div 
					className="h4"
					css={css`
						margin-bottom: 30px;
						${mobile}{
							margin-bottom: 22px;
						}
				`}>
						{videoTitle}
				</div>
				{videoText && 
					<div css={css`margin-bottom: 30px;`}>
							{videoText}
					</div>
				}
				<Button
					to={resolveLink(videoLink?.to?._type, videoLink?.to?.slug?.current, videoLink?.to?.defaultParentCategory?.slug?.current)}
					css={css`
						margin-bottom: 10px;
					`}
				>
					{videoLink?.linkText}
				</Button>	
		</div>
		<div css={css`
			grid-column: 4 / 13;
			grid-row: 1 / 3;
			/* align-self: end; */
			position: relative;
			${mobile}{
				grid-column: 1 / 13;
				grid-row: 1;
				margin-bottom: 30px;
			}
		`}>
			<video playsInline autoPlay muted loop css={css`
				width: 100%;
				/* height: 100%; */
				/* margin-bottom: 120px; */
			`}>
				<source src={video?.asset?.url} />
			</video>
		</div>
	</Section>
)}

export default HomeVideo
