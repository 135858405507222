import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Seo from "~components/Seo"
import { graphql } from "gatsby"
import { breakpoints } from '~styles/global'
import HomeSliderSection from "~components/sections/HomeSliderSection"
import ProductGridSection from "~components/sections/ProductGridSection"
import CategoryGridSection from "~components/sections/CategoryGridSection"
import FeaturedCategorySection from "~components/sections/FeaturedCategorySection"
import CTASection from "~components/sections/CTASection"
import resolveLink from '~utils/resolveLink'
import Section from '~components/Section'
import Image from '~components/Image'
import RichText from '~components/RichText'
import Button from '~components/blocks/Button'
import HomeVideo from "../components/HomeVideo"

const {mobile, tablet} = breakpoints

const IndexPage = ( data ) => {

  const homepage = data?.data?.sanityHome
  const homeContent = homepage?._rawContent

	console.log({homeContent})
  return (
    <>
      <Seo
        title={homepage?.title}
        image={homepage?.content?.seo?.socialImage?.asset?.url}
        description={homepage?.content?.seo?.metaDescription}
        metaTitle={homepage?.content?.seo?.metaTitle}
				url="/"
      />
			{homeContent?.mediaType === 'slider' ?
			<HomeSliderSection 
			slides={homeContent?.slider}
			headline={homeContent?.sliderHeading}
			css={css`
				margin-bottom: 128px;
				display: grid;
				min-height: calc(100vh - 182px);
				padding-top: 20px;
				align-items: end;
				${tablet}{
					min-height: auto;
					padding-top: 120px;
				}       
				${mobile}{
					padding-top: 0px;
					margin-bottom: 110px;
				}
			`}/> :
			<HomeVideo 
				video={homeContent?.video} 
				videoTitle={homeContent?.videoTitle} 
				videoText={homeContent?.videoText} 
				videoLink={homeContent?.videoLink}
				css={css`
				margin-bottom: 258px;
				display: grid;
				min-height: calc(100vh - 182px);
				padding-top: 20px;
				/* align-items: end; */
				${tablet}{
					min-height: auto;
					padding-top: 120px;
				}       
				${mobile}{
					padding-top: 0px;
					margin-bottom: 220px;
				}
			`}
			/>
		}
      
			<CategoryGridSection
        categoriesLink={resolveLink('allProducts', 'null')} 
        categoriesLinkHeading={homeContent?.categoriesLinkHeading} 
        categories={homeContent?.featuredParentCategories}
        css={css`
          margin-bottom: 116px;
          ${mobile}{
            margin-bottom: 100px;
          }
        `}
      />
      <ProductGridSection 
        storeLinkHeading={homeContent?.storeLinkHeading} 
        storeLink={resolveLink('allProducts', 'null')} 
        products={homeContent?.featuredProducts}
        css={css`
          margin-bottom: 280px;
          ${mobile}{
            margin-bottom: 152px;
          }
        `}
      />
      {homeContent?.featuredCategories.map((featuredCategory, i) =>(
        <>
        <FeaturedCategorySection 
          key={featuredCategory?._key}
          image={featuredCategory?.image?.asset}
          title={featuredCategory?.title}
          subtitle={featuredCategory?.subtitle}
          imageTitle={featuredCategory?.imageTitle}
          text={featuredCategory?.text}
          borderBottom={homeContent?.featuredCategories.length ===  i + 1}
          link={resolveLink(featuredCategory?.link?._type, featuredCategory?.link?._type === 'productCategory' ? featuredCategory?.link?.slug?.current : featuredCategory?.link?.title,
            featuredCategory.link?.defaultParentCategory?.slug?.current)}
          reverse={i%2 === 1}
          css={css`
              margin-bottom: 200px;
              ${mobile}{
                padding-top: ${i === i * 2 ? `0px` : `100px`};
                margin-bottom: ${homeContent?.featuredCategories.length !==  i + 1 ? `0px` : `110px;`};
              }
          `}
        />
        </>
      ))}
			<AboutSection>
				<AboutImage
            asset={homeContent?.aboutImage}
            aspectRatio={0.719}
          />
				<AboutText>
					<RichText content={homeContent?.aboutText} />
					<Button 
                to='/about'
                css={css`
                  padding-top: 40px;
                `}
              >{homeContent?.aboutButtonText}</Button>
				</AboutText>
			</AboutSection>
      <CTASection 
        title={homeContent.cta?.title}
        image={homeContent.cta?.image} 
				text={homeContent.cta?.text} 
        link={homeContent.cta?.link?.to ? resolveLink(homeContent.cta?.link?.to?._type, homeContent.cta?.link?.to?.slug?.current) : undefined}
        buttonText={homeContent.cta?.link?.linkText}
      />
    </>
  )
}

const AboutSection = styled(Section)`
	margin-bottom: 200px;
	${mobile}{
		margin-bottom: 120px;
	}
	> div {
		align-items: center;
	}
`

const AboutImage = styled(Image)`
	grid-column: 2 / 6;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 40px;
	}
`

const AboutText = styled.div`
	grid-column: 7 / 12;
	max-width: 600px;
	${mobile}{
		grid-column: span 12;
	}
`

export const query = graphql`
  query{
      sanityHome {
      _rawContent(resolveReferences: {maxDepth: 10})
      title
      slug {
        current
      }
      content {
        seo {
          metaDescription
          metaTitle
          socialImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
