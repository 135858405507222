import React from 'react'
import PropTypes from 'prop-types';
import SliderBlock from '~components/blocks/SliderBlock'

const HomeSliderSection = ({ className, slides, headline  }) => {
  return (
    <SliderBlock slides={slides} headline={headline} className={className}/>
  )
}

HomeSliderSection.propTypes = {
  slides: PropTypes.array,
  className: PropTypes.string,
  headline: PropTypes.string,
}

HomeSliderSection.defaultProps = {
  headline: 'Slider heading',
  slides: [
    { 
      key:"image1", 
      link: "/",
      product: {
        title:"Slide Title 1", 
        slug: {
          current: `/`,
        },
        _type: `/`,
        content: {
          brand: {
            title: "Brand 1", 
          }
        }
      }
    },
    { key:"image2", title:"Slide Title 2", brand:"Brand 2", link: "/"},
  ],
}

export default HomeSliderSection
