import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Button from '~components/blocks/Button'
import Image from '~components/Image'
import { Link } from "gatsby"

const CategoryCard = ({ title, image, text, link, className, aspectRatio }) => {

  const {mobile} = breakpoints
  const [hovered, setHovered] = useState(false)

  return (
    <Link
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      to={link}
    >
      <div css={css`
        margin-bottom: 28px;
        @media (max-width: 750px) {
          margin-bottom: 17px;
        }
      `}>
        <div css={css`
          transform: ${hovered ? 'translateY(-10px)' : 'translateY(0px)'};
          transition: 0.3s ease-in-out transform;
          @media (max-width: 750px) {
            transform: none;
          }
        `}>
          <Image
            asset={image}
            aspectRatio={aspectRatio ? aspectRatio : 0.71}
          />
        </div>
      </div>
      <h4 css={css`
        margin-bottom: 34px;
      `}>
        {title}
      </h4>
      <div css={css`
          /* opacity: ${hovered ? '1' : '0'};
          transition: ${hovered ? '0.4s 0.05s' : '0.4s'}; */
          
          @media (max-width: 750px) {
            display: none;
          }
      `}>
        <p css={css`
            padding-bottom: 36px;
        `}>
          {text}
        </p>
        <Button css={css`display: inline-grid;`} noLink>See Collection</Button>
      </div>
    </Link>
  )
}

CategoryCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  text: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
	aspectratio: PropTypes.number,
}

CategoryCard.defaultProps = {
  title: 'Living',
  text: 'The beauty we surround ourselves in, particularly within our living quarters, has a profound impact on our space.',
  link: '/',
}

export default CategoryCard
